(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-additions-components/assets/javascripts/sportkryss/sportkryss-number-area.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-additions-components/assets/javascripts/sportkryss/sportkryss-number-area.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useState,
  useRef,
  useEffect,
  useCallback
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  ReactButton,
  ReactBadge
} = svs.ui;
const {
  ControlGroup,
  Input
} = svs.ui.reactForm;
const {
  actions
} = svs.components.tipsen.engine;
const {
  selectSportkryssNumber,
  selectCurrentSportkryssFactor
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  generateSportkryssNumber
} = svs.components.tipsen.engine.utils;
const {
  SPORTKRYSS_SIZE
} = svs.components.tipsen.engine.constants;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const SportkryssNumberArea = () => {
  const couponId = useCouponId();
  const dispatch = useDispatch();
  const currentSportkryssFactor = useSelector(state => selectCurrentSportkryssFactor(state, couponId));
  const currentSportkryssNumber = useSelector(state => selectSportkryssNumber(state, couponId));
  useEffect(() => {
    document.documentElement.style.setProperty('--sportkryss-size', SPORTKRYSS_SIZE);
  }, []);
  const presentationRef = useRef();
  const inputRef = useRef();
  const [inputState, setInputState] = useState({
    editing: false,
    inputNumber: currentSportkryssNumber
  });
  const submitSportkryssInput = useCallback(() => {
    setInputState(currentInputState => _objectSpread(_objectSpread({}, currentInputState), {}, {
      editing: false
    }));
    if (inputState.inputNumber.length > 0) {
      dispatch(actions.setSportkryssNumber({
        couponId,
        sportkryssNumber: generateSportkryssNumber(inputState.inputNumber)
      }));
    }
  }, [couponId, dispatch, inputState.inputNumber]);
  const onSportkryssClick = () => {
    if (!currentSportkryssFactor) {
      return;
    }
    if (!inputState.editing) {
      setInputState(_objectSpread(_objectSpread({}, inputState), {}, {
        editing: true,
        inputNumber: currentSportkryssNumber
      }));
      inputRef.current.focus();
      inputRef.current.select();
    }
  };
  const onSportkryssInputBlur = () => {
    submitSportkryssInput();
  };
  const onSportkryssKeyDown = event => {
    const {
      key,
      keyCode
    } = event;
    if (key === 'Enter' || keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  };
  const onSportkryssInputChange = _ref => {
    let {
      target
    } = _ref;
    const regexp = /^[0-9\b]+$/;
    const valueIsValid = regexp.test(target.value);
    if (target.value === '' || valueIsValid) {
      setInputState(_objectSpread(_objectSpread({}, inputState), {}, {
        inputNumber: target.value
      }));
    }
  };
  const numberContainerClassList = ['number-container'];
  !currentSportkryssFactor && numberContainerClassList.push('disabled');
  return React.createElement("div", {
    className: "sportkryss-number-area"
  }, React.createElement("div", {
    className: numberContainerClassList.join(' ')
  }, React.createElement("div", {
    className: "number-presentation".concat(inputState.editing ? ' hidden' : ''),
    "data-testid": "number-presentation-wrapper",
    onClick: onSportkryssClick,
    ref: presentationRef
  }, Array.from(currentSportkryssNumber).map((num, index) => {
    const key = "".concat(index, "_").concat(num);
    return React.createElement("span", {
      key: key
    }, React.createElement(ReactBadge, {
      className: "number-integer badge-bounce ".concat(!currentSportkryssFactor ? 'disabled' : '')
    }, num));
  })), React.createElement("div", {
    className: "number-input".concat(inputState.editing ? '' : ' hidden'),
    "data-testid": "number-input-wrapper"
  }, React.createElement(ControlGroup, null, React.createElement(Input, {
    autoComplete: "off",
    maxLength: SPORTKRYSS_SIZE,
    onBlur: onSportkryssInputBlur,
    onChange: onSportkryssInputChange,
    onKeyDown: onSportkryssKeyDown,
    refElem: inputRef,
    type: "tel",
    value: inputState.inputNumber
  })))), React.createElement(ReactButton, {
    block: true,
    "data-testid": "sportkryss-button",
    disabled: !currentSportkryssFactor,
    onClick: () => dispatch(actions.setSportkryssNumber({
      couponId,
      sportkryssNumber: generateSportkryssNumber()
    })),
    size: 300,
    transparent: true
  }, "Slumpa"));
};
setGlobal('svs.components.tipsen.couponAdditionsComponents.sportkryss.SportkryssNumberArea', SportkryssNumberArea);

 })(window);