(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-additions-components/assets/javascripts/addon-pix.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-additions-components/assets/javascripts/addon-pix.js');
"use strict";

const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  RadioGroup,
  ControlGroup,
  RadioButton,
  Fieldset,
  Form
} = svs.ui.reactForm;
const {
  Currency: currency
} = svs.utils.format;
const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const {
  ProductHeader
} = svs.components.lbUi.productHeader;
const {
  actions
} = svs.components.tipsen.engine;
const {
  selectAddonPixSizes
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectCurrentAddonPixSizeIndex
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useRelatedDraw
} = svs.components.sport.drawsRedux.hooks;
const {
  DialogContextual
} = svs.components.tipsen;
const defaultJackpotText = 'chans att vinna minst 50 miljoner!';
const {
  selectJackpotById,
  selectGuaranteedJackpotById
} = svs.components.sport.drawsRedux.selectors;
const BannerText = () => React.createElement("div", {
  className: "tipsen-additions-pix-banner"
}, React.createElement("span", {
  className: "f-bold"
}, "PIX!"), React.createElement("span", null, " F\xE4rdiga spel. Helt enkelt."));
const RightButton = _ref => {
  let {
    regCloseTime
  } = _ref;
  return React.createElement("div", {
    className: "tipsen-addon-pix-dialog-contextual"
  }, React.createElement(DialogContextual, {
    color: "fc-white"
  }, "Fulltr\xE4ff \xE4r ett sportspel d\xE4r du tippar antal m\xE5l i 13 matcher. De tre b\xE4sta vinstgrupperna vinner! Spelstopp: ".concat(formatDate(regCloseTime, true, true, true))));
};
const specialCurrency = curr => {
  const fractionChar = ',';
  const million = 1000000;
  const currInt = curr ? parseInt(curr.replace(/\s/, ''), 10) : 0;
  if (currInt === million) {
    return '1 miljon';
  } else if (currInt > million) {
    let millionFraction = (currInt / million).toString();
    millionFraction = millionFraction.replace('.', fractionChar);
    const commaPos = millionFraction.indexOf(fractionChar);
    if (commaPos !== -1) {
      const millionPart = millionFraction.substring(0, commaPos);
      const firstFraction = millionFraction[commaPos + 1];
      if (firstFraction === '0') {
        millionFraction = millionPart;
      } else {
        millionFraction = "".concat(millionPart).concat(fractionChar).concat(firstFraction);
      }
    }
    return "".concat(millionFraction, " miljoner");
  }
  return currency(curr);
};
const AddonPix = _ref2 => {
  var _ref3, _jackpot$jackpotAmoun;
  let {
    pixProductId,
    productId,
    drawNumber
  } = _ref2;
  const drawLite = useRelatedDraw({
    relatedProductId: pixProductId,
    productId,
    drawNumber
  });
  const dispatch = useDispatch();
  const relatedCouponId = "".concat(pixProductId, "_").concat(drawLite === null || drawLite === void 0 ? void 0 : drawLite.drawNumber, "_pix");
  const pixSizes = useSelector(selectAddonPixSizes);
  const selectedPixIndex = useSelector(state => selectCurrentAddonPixSizeIndex(state, relatedCouponId));
  const jackpot = useSelector(state => selectJackpotById(state, drawLite === null || drawLite === void 0 ? void 0 : drawLite.productId, drawLite === null || drawLite === void 0 ? void 0 : drawLite.drawNumber));
  const guaranteedJackpot = useSelector(state => selectGuaranteedJackpotById(state, drawLite === null || drawLite === void 0 ? void 0 : drawLite.productId, drawLite === null || drawLite === void 0 ? void 0 : drawLite.drawNumber));
  if (!drawLite || Object.keys(drawLite).length === 0) {
    return null;
  }
  const jackpotAmount = (_ref3 = (_jackpot$jackpotAmoun = jackpot === null || jackpot === void 0 ? void 0 : jackpot.jackpotAmount) !== null && _jackpot$jackpotAmoun !== void 0 ? _jackpot$jackpotAmoun : guaranteedJackpot === null || guaranteedJackpot === void 0 ? void 0 : guaranteedJackpot.jackpotAmount) !== null && _ref3 !== void 0 ? _ref3 : 0;
  const handleChange = _ref4 => {
    let {
      couponId,
      addonPixChoice
    } = _ref4;
    dispatch(actions.selectPixSize({
      couponId,
      addonPixChoice
    }));
  };
  return React.createElement("div", {
    className: "tipsen-addition-pix tipsen-addition-grid tipsen-addition"
  }, React.createElement("div", {
    className: "tipsen-addon-pix"
  }, React.createElement("div", {
    className: "tipsen-addon-pix-header-content"
  }, React.createElement("div", {
    className: "tipsen-addon-pix-header-branding"
  }, React.createElement(ProductHeader, {
    branding: "fulltraff",
    rightButton: React.createElement(RightButton, {
      regCloseTime: drawLite.regCloseTime
    }),
    size: 100
  })), React.createElement("div", {
    className: "tipsen-addon-pix-header-splash"
  }), React.createElement("div", {
    className: "tipsen-addon-pix-header-text"
  }, React.createElement("span", null, "L\xE4gg till ett spel och ha"), React.createElement("span", null, jackpotAmount ? "chans att vinna ".concat(specialCurrency(jackpotAmount), "!") : defaultJackpotText))), React.createElement("div", {
    className: "tipsen-addon-pix-bottom-content"
  }, React.createElement("div", {
    className: "addon-pix-banner-text"
  }, React.createElement(BannerText, null)), React.createElement(Form, null, React.createElement(Fieldset, null, React.createElement(ControlGroup, null, React.createElement(RadioGroup, {
    size: "200"
  }, pixSizes.map((size, index) => {
    const checked = index === selectedPixIndex;
    return React.createElement(RadioButton, {
      "aria-checked": checked,
      checked: checked,
      className: checked ? 'f-bold' : '',
      inverted: "true",
      key: size,
      onChange: () => handleChange({
        couponId: relatedCouponId,
        addonPixChoice: index
      }),
      tabIndex: checked ? 0 : -1,
      value: size
    }, size ? "".concat(size, " kr") : 'Nej');
  }))))))));
};
setGlobal('svs.components.tipsen.couponAdditionsComponents.AddonPix', AddonPix);

 })(window);