(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-additions-components/assets/javascripts/weeks.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-additions-components/assets/javascripts/weeks.js');
"use strict";

const {
  useMemo
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  RadioGroup,
  ControlGroup,
  RadioButton,
  Fieldset,
  Form
} = svs.ui.reactForm;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  actions
} = svs.components.tipsen.engine;
const {
  selectCurrentWeek
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  selectWeeks
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  BaseAddons
} = svs.components.tipsen.baseAddons;

const Weeks = _ref => {
  let {
    title,
    icon,
    description,
    getDialog,
    legend,
    presentableWeek
  } = _ref;
  const dispatch = useDispatch();
  const weeks = useSelector(selectWeeks);
  const couponId = useCouponId();
  const currentWeek = useSelector(state => selectCurrentWeek(state, couponId));
  const dialog = useMemo(() => getDialog(weeks), [getDialog, weeks]);
  return React.createElement(BaseAddons, {
    description: description,
    dialog: dialog,
    icon: icon,
    id: "weeks",
    title: title
  }, React.createElement(Form, {
    className: "tipsen-addition-form"
  }, React.createElement(Fieldset, null, React.createElement("legend", {
    className: "sr-only"
  }, legend), React.createElement(ControlGroup, null, React.createElement(RadioGroup, {
    size: "200"
  }, weeks.map((week, index) => {
    const checked = currentWeek === week;
    return React.createElement(RadioButton, {
      "aria-checked": checked,
      checked: checked,
      className: checked ? 'f-bold' : '',
      key: week,
      onChange: () => dispatch(actions.selectWeek({
        couponId,
        week: index
      })),
      tabIndex: checked ? 0 : -1,
      value: week
    }, presentableWeek(week));
  }))))));
};
setGlobal('svs.components.tipsen.couponAdditionsComponents.Weeks', Weeks);

 })(window);